import clsx from 'clsx'
import { forwardRef, useImperativeHandle, useMemo } from 'react'

import s from './AppButton.module.scss'
import { ButtonProps } from './AppButton.types'
import { generateButtonCssVars } from './generateButtonCssVars/generateButtonCssVars'
import { IconWrapper } from './IconWrapper/IconWrapper'
import { Loader } from './Loader/Loader'
import { useActiveIndicator } from './useActiveIndicator/useActiveIndicator'

export const AppButton = forwardRef<HTMLButtonElement, ButtonProps>(({
  className, variant = 'primary', size = 'l', iconAlignment = 'left', type = 'button',
  icon: Icon = null, fillContainer, onClick, text, justifyCenter = true, showLoader, style, ...rest
}, ref) => {

  const buttonRef = useActiveIndicator<HTMLButtonElement>({
    disabled: showLoader,
  })

  const buttonVars = useMemo(() => generateButtonCssVars(variant), [variant])

  useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement, [])

  return (
    <button
      ref={buttonRef}
      type={type}
      style={{ ...buttonVars, ...style }}
      className={clsx(
        s.button,
        s[variant],
        s[size],
        Icon && !text && s.square,
        fillContainer && s.fillContainer,
        showLoader && s.loading,
        justifyCenter && s.justifyCenter,
        className,
      )}
      onClick={showLoader ? undefined : onClick}
      {...rest}
    >
      {
        showLoader && (
          <Loader
            className={s.buttonLoader}
          />
        )
      }
      <IconWrapper
        createWrapper={!!Icon && !!text}
        iconAlignment={iconAlignment}
        justifyCenter={justifyCenter}
        gap="l"
      >
        { typeof Icon === 'function' ? <Icon /> : Icon }
        {
          text && (
            <span>
              {text}
            </span>
          )
        }
      </IconWrapper>
    </button>
  )

})
