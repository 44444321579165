import { ButtonCSSProperties, ButtonVariant } from '../AppButton.types'

interface ColorModifier {
  bg?: string;
  bgHover?: string;
  content?: string;
  contentHover?: string;
}

const constructVarObject = (modifiers: ColorModifier): ButtonCSSProperties => {
  const { bg, bgHover, content, contentHover } = modifiers
  const defaultBgColor = 'transparent'
  const defaultContentColor = '#fff'

  return {
    '--bg-color': bg ? bg : defaultBgColor,
    '--bg-hover-color': bgHover ? bgHover : defaultBgColor,
    '--content-color': content ? content : defaultContentColor,
    '--content-hover-color': contentHover ? contentHover : defaultContentColor,
  }
}

/**
 * Генерирует css variables для цветов (фон/контент) кнопки в зависимости от переданной темы и варианта.
 */
export const generateButtonCssVars = (variant: ButtonVariant): ButtonCSSProperties => {
  switch (variant) {
    case 'primary': {
      const colorModifierMap: ColorModifier = { bg: '#4CAAE9', bgHover: '#3980AF', content: '#fff', contentHover: '#fff' }

      return constructVarObject(colorModifierMap)
    }
    case 'secondary': {
      const colorModifierMap: ColorModifier = { bg: '#fff', bgHover: '#fff', content: '#4CAAE9', contentHover: '#3980AF' }

      return constructVarObject(colorModifierMap)
    }
  }
}
