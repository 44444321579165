import { to } from '@/shared/lib'

import Build from '../../ui/build-on.png'
import Friends from '../../ui/friends-on.png'
import Labs from '../../ui/labs-on.png'
import Quests from '../../ui/quests-on.png'

interface MenuItem {
  path: string;
  name: string;
  imgPath: string;
  disabled?: boolean;
}

export const menuItems: MenuItem[] = [
  {
    name: 'Labs',
    path: to.game(),
    imgPath: Labs,
    disabled: true,
  },
  {
    name: 'Build',
    path: to.upgrades(),
    imgPath: Build,
    disabled: true,
  },
  {
    name: 'Quests',
    path: to.quests(),
    imgPath: Quests,
    disabled: true,
  },
  {
    name: 'Squad',
    path: to.main(),
    imgPath: Friends,
  },
]
