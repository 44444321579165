import { AppButton, ButtonProps, VStack } from '@/shared/ui'

interface ActionButtonsProps {
  btns?: ButtonProps[];
}

export const ActionButtons = ({ btns }: ActionButtonsProps) => {

  return (
    <VStack max gap="12">
      {
        btns?.map(({ children, ...rest }, i) => (
          <AppButton key={i} {...rest}>
            {children}
          </AppButton>
        ))
      }
    </VStack>
  )
}
