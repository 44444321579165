import { useMutation } from '@tanstack/react-query'

import { useApi } from '@/shared/lib'

import { loginKeys } from '../lib'

export const useLogin = () => {
  const { api } = useApi()

  return useMutation({
    mutationKey: loginKeys.login(),
    mutationFn: async (): Promise<void> => {
      await api.login.loginCreate()
    },
  })
}
