import './styles/global.scss'
import './styles/fonts/connect.scss'

import { setDebug } from '@telegram-apps/sdk-react'
import { type FC, Suspense, useEffect } from 'react'

import { LoginGate } from '@/features/login'
import { AppLoader } from '@/shared/ui'

import { AppRouter, ErrorBoundarySDK, withProviders } from './providers'
import { ExpandedProvider } from './providers/expanded'

const AppContent: FC = () => {
  useEffect(() => {
    if (!import.meta.env.DEV && !import.meta.env?.['VITE_DOMAIN'].includes('dev')) return

    setDebug(true)
    import('eruda').then(it => it.default.init())
  }, [])

  return (
    <Suspense fallback={<AppLoader />}>
      <div className="layout">
        <ExpandedProvider>
          <LoginGate error={ErrorBoundarySDK} loading={AppLoader} initial={AppLoader}>
            <AppRouter />
          </LoginGate>
        </ExpandedProvider>
      </div>
    </Suspense>
  )
}

export const App = withProviders(AppContent)
