import { useEffect, useMemo, useRef } from 'react'
import { useBoolean } from 'usehooks-ts'

import { FCC } from '../misc'
import { AnimationLibrariesContext, AnimationLibrariesContextPayload, GestureType, SpringType } from './animationLibrariesContext'

const loadAnimationLibraries = async (): Promise<[SpringType, GestureType]> => Promise.all([
  import('@react-spring/web'),
  import('@use-gesture/react'),
])

export const AnimationLibrariesProvider: FCC = ({ children }) => {
  const librariesRef = useRef<Omit<AnimationLibrariesContextPayload, 'librariesLoaded'>>({})
  const { value: librariesLoaded, setTrue: finishLoading } = useBoolean()

  useEffect(() => {
    loadAnimationLibraries()
      .then(([ spring, gesture ]) => librariesRef.current = { spring, gesture })
      .then(finishLoading)
  }, [])

  const memorizedValue: AnimationLibrariesContextPayload = useMemo(() => ({
    ...librariesRef.current,
    librariesLoaded,
  }), [ librariesLoaded ])

  return (
    <AnimationLibrariesContext.Provider value={memorizedValue}>
      {children}
    </AnimationLibrariesContext.Provider>
  )
}
