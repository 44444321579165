import clsx from 'clsx'

import { FCC } from '@/shared/lib'

import { ButtonProps } from '../AppButton.types'
import s from './IconWrapper.module.scss'

interface IconWrapperProps extends Pick<ButtonProps, 'iconAlignment'> {
  createWrapper: boolean;
  justifyCenter?: boolean;
  gap?: 'l' | 's';
}

export const IconWrapper: FCC<IconWrapperProps> = (
  { createWrapper, iconAlignment = 'left', justifyCenter, gap = 'l', children },
) =>
  createWrapper
    ? (
      <div className={clsx(
        s.iconWrapper,
        s[iconAlignment],
        s[gap],
        justifyCenter && s.justifyCenter,
      )}
      >
        {children}
      </div>
    )
    : (
      <>
        {children}
      </>
    )
