import clsx from 'clsx'
import {
  CSSProperties,
  memo, ReactNode, useCallback, useEffect, useRef,
  useState } from 'react'

import { AnimationLibrariesProvider, useAnimationLibs } from '@/shared/lib'

import { ButtonProps } from '../Button'
import { ActionButtons } from './ActionButtons/ActionButtons'
import s from './AppDrawer.module.scss'

interface DrawerProps {
  className?: string;
  children: ReactNode;
  isOpen?: boolean;
  openHeight?: number;
  closedHeight?: number;
  onClose?: () => void;
  onOpen?: () => void;
  lazy?: boolean;
  removeFromDomOnClose?: boolean;
  autoHeight?: boolean;
  btns: ButtonProps[];
}

const windowHeight = window.innerHeight
const HOLD_HEIGHT = 5

/**
 * TODO: со шторой вообще помойка получается. Нужен серьезный рефактор
 */
export const DrawerContent = memo((props: DrawerProps) => {
  const {
    autoHeight,
    className,
    children,
    onOpen,
    onClose,
    isOpen,
    openHeight: propHeight = windowHeight - 12,
    closedHeight = 0,
    btns,
    removeFromDomOnClose = true,
  } = props

  const [ observedHeight, setObservedHeight ] = useState<number>(propHeight)
  const observerRef = useRef<ResizeObserver | null>(null)
  const contentWrapperRef = useRef<HTMLDivElement | null>(null)
  const [ isClosed, setIsClosed ] = useState(!isOpen)

  const openHeight = autoHeight ? observedHeight : propHeight

  const { spring, gesture } = useAnimationLibs()
  const [ { y }, api ] = spring.useSpring(() => ({ y: openHeight - closedHeight }))

  // следим за высотой если надо
  useEffect(() => {
    if (!autoHeight || !contentWrapperRef.current || isClosed) return

    observerRef.current = new ResizeObserver(entries => {
      const wrapperEntry = entries[0]

      if (!wrapperEntry) return

      const { contentRect: { height: wrapperHeight } } = wrapperEntry
      // ограничиваем высоту. Не более чем высота экрана.
      const boundedHeight = Math.min(windowHeight, wrapperHeight + HOLD_HEIGHT)
      setObservedHeight(boundedHeight)
    })

    observerRef.current?.observe(contentWrapperRef.current)

    return () => {
      // здесь отключаем обсервер и чистим реф
      observerRef.current?.disconnect()
      observerRef.current = null
    }
  }, [ autoHeight, isClosed ])

  const openDrawer = useCallback(() => {
    if (contentWrapperRef.current) observerRef.current?.observe(contentWrapperRef.current)

    api.start({ y: 0, immediate: false, onResolve: onOpen })
  }, [ api ])

  const close = (velocity = 0) => {
    // При закрытии выключаем отслеживание
    if (contentWrapperRef.current) observerRef.current?.unobserve(contentWrapperRef.current)

    api.start({
      y: openHeight + 220,
      immediate: false,
      config: { ...spring.config.stiff, velocity },
      onResolve: () => {
        onClose?.()
        removeFromDomOnClose && setIsClosed(true)
      },
    })
  }

  useEffect(() => {
    isOpen && setIsClosed(false)
    isOpen ? openDrawer() : close()
  }, [ isOpen, openDrawer ])

  const bind = gesture.useDrag(
    ({
      last,
      velocity: [ , vy ],
      direction: [ , dy ],
      movement: [ , my ],
      offset: [ , oy ],
      down,
    }) => {
      if (oy < -80) return openDrawer()
      if (oy > openHeight - 100) return close()

      if (!last) return api.start({ y: oy, immediate: down && Math.abs(my) > 50 })

      if (my > openHeight * 0.5 || vy > 0.5 && dy >= 0) return close()

      my && openDrawer()

      return
    },
    { from: () => [ 0, y.get() ], filterTaps: true, rubberband: true, bounds: { top: 50 } },
  )

  if (isClosed) return null

  return (
    <div className={s.drawer}>
      <spring.a.div
        className={clsx(s.sheet, className)}
        style={{ y, height: openHeight + 220 }}
        {...bind()}
      >
        <div className={s.sheetContent}>
          <div className={s.holdWrapper}>
            <div className={s.hold} />
          </div>
          <div
            className={s.contentWrapper}
            style={{ '--drawer-height': `${openHeight + 220}px` } as CSSProperties}
          >
            <div
              ref={contentWrapperRef}
              style={{ height: autoHeight ? undefined : '80%' }}
            >
              {children}
            </div>
            <ActionButtons btns={btns} />
          </div>
        </div>
      </spring.a.div>
    </div>
  )
})

const DrawerAsync = (props: DrawerProps) => {
  const { librariesLoaded } = useAnimationLibs()

  if (!librariesLoaded) return null

  return <DrawerContent {...props} />
}

export const Drawer = (props: DrawerProps) => (
  <AnimationLibrariesProvider>
    <DrawerAsync {...props} />
  </AnimationLibrariesProvider>
)
