import { bindViewportCSSVars, postEvent, supports, useLaunchParams, useViewport } from '@telegram-apps/sdk-react'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useTimeout } from 'usehooks-ts'

import { FCC, useMaxSizes } from '@/shared/lib'
import { AppLoader } from '@/shared/ui'

export const ExpandedProvider: FCC = ({ children }) => {
  const [expandedOnce, setExpandedOnce] = useState(false)
  const launchParams = useLaunchParams()

  useEffect(() => {
    if (supports('web_app_expand', launchParams.version)) {
      postEvent('web_app_expand')
    }
    if (supports('web_app_request_write_access', launchParams.version)) {
      postEvent('web_app_request_write_access')
    }
  }, [launchParams])

  /**
     * Prevents mini app from being closed on scrolling.
     * When there is a margin at the top, page is somewhat "scrolled down",
     * so scrolling to the top is not detected as attempt to close the app.
     */
  const overflow = 100
  const sizes = useMaxSizes()
  useLayoutEffect(() => {
    document.body.style.marginTop = `${overflow}px`
    document.body.style.height = sizes.height + overflow + 'px'
    document.body.style.paddingBottom = `${overflow}px`
    window.scrollTo({ left: 0, top: overflow })
  }, [sizes.height]) // resizing when height is changing, stableHeight prevents excess resizes

  // workaround for not visible items in lists
  useTimeout(() => window.scrollTo({ left: 0, top: overflow + 1 }), 100)
  useTimeout(() => window.scrollTo({ left: 0, top: overflow }), 200)

  // workaround for telegram not setting isExpanded=true at random moments
  useTimeout(() => {
    setExpandedOnce(true)
  }, 5000)

  // part of the sdk init
  const viewport = useViewport()
  useEffect(() => {
    return viewport && bindViewportCSSVars(viewport)
  }, [viewport])

  useEffect(() => {
    if (!expandedOnce && viewport?.isExpanded) {
      setExpandedOnce(true)
    }
  }, [viewport?.isExpanded])

  if (!expandedOnce) {
    return <AppLoader />
  }

  return children
}
