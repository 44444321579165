import React, { CSSProperties } from 'react'

import { IconName, icons } from './icons'

export interface IconProps {
  name: IconName
  className?: string
  size?: [width: number | string, height: number | string]
  style?: Omit<CSSProperties, 'width' | 'height' | 'minHeight' | 'minWidth'>
}

export const Icon: React.FC<IconProps> = ({ name, className, size = [24, 24], style }) => {
  const { Component } = icons[name]

  return (
    <Component
      className={className}
      style={{
        ...(size ? {
          width: size[0],
          height: size[1],
          minHeight: size[1],
          minWidth: size[0],
        } : undefined),
        ...style,
      }}
    />
  )
}
