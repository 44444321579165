import { Icon } from '../Icon'
import { VStack } from '../Stack'
import s from './AppLoader.module.scss'

export const AppLoader = () => {

  return (
    <VStack
      align='center'
      gap='16'
      className={s.container}
    >
      <div>
        <Icon name='logo' size={[72, 88]} />
      </div>
      <div className={s.text}>
        FileMarket
        <span className={s.textGradient}>
          AI
        </span>
      </div>
    </VStack>
  )
}
