import { SDKProvider } from '@telegram-apps/sdk-react'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { registerSW } from 'virtual:pwa-register'

import { App } from './app/App'
import { SDKGate } from './app/providers'
import { ApiProvider } from './app/providers/api'
import { AppLoader } from './shared/ui'

const root = ReactDOM.createRoot(document.getElementById('root')!)

registerSW({ immediate: true })

root.render(
  <StrictMode>
    <SDKProvider acceptCustomStyles>
      <SDKGate loading={AppLoader} initial={AppLoader}>
        <ApiProvider>
          <App />
        </ApiProvider>
      </SDKGate>
    </SDKProvider>
  </StrictMode>,
)
