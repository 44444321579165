import clsx from 'clsx'
import { matchPath, NavLink, useLocation } from 'react-router-dom'

import { menuItems } from '../model/menuItems/menuItems'
import s from './Menu.module.scss'

export const Menu = () => {
  //assigning location variable
  const location = useLocation()

  //destructuring pathname from location
  const { pathname } = location

  return (
    <div className={clsx(s.container)}>
      {
        menuItems.map(({ imgPath, name, path, disabled }) => (
          <NavLink
            key={name}
            to={path}
            className={clsx(
              s.item,
              matchPath(path, pathname) && s.active,
              disabled && s.disabled,
            )}
          >
            <img src={imgPath} alt={name} />
            <span className={s.name}>
              {name}
            </span>
          </NavLink>
        ))
      }
    </div>
  )
}
