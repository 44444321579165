import clsx from 'clsx'
import { HTMLAttributes } from 'react'

import s from './Loader.module.scss'

export const Loader = ({ className, ...rest }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={clsx(s.loader, className)}
    {...rest}
  >
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)
