export const createCustomFetch = (initDataRaw: string | undefined): typeof fetch =>
  (req, init) => {

    // TODO: add startapp
    const headers = {
      ...init?.headers,
      Authorization: `tma ${initDataRaw}`,
    }

    return fetch(req as RequestInfo | URL, {
      ...init,
      headers,
    })
  }
