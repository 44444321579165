import { createMediaQuery } from '../media/createMediaQuery'

type InitialInteractionEventKey = keyof Pick<HTMLElementEventMap, 'mousedown' | 'touchstart'>;
type ExitInteractionEventKey = keyof Pick<HTMLElementEventMap, 'mouseup' | 'touchend'>;
type MoveInteractionEventKey = keyof Pick<HTMLElementEventMap, 'mousemove' | 'touchmove'>;

export type InteractionEventKeyTuple = [
    initEventKey: InitialInteractionEventKey,
    exitEventKey: ExitInteractionEventKey,
    moveEventKey: MoveInteractionEventKey,
];

/**
 * Возвращает ключи для add/remove EventListener. Возвращаемые ключи применяются для обработки интерактива
 * "нажатия" - клик на десктопе и тач на touch устройствах
 */
export const getInteractionEventKeys = (): InteractionEventKeyTuple => {
  const isTouchDevice = createMediaQuery('(hover: none)')?.matches ?? false

  return isTouchDevice
    ? [ 'touchstart', 'touchend', 'touchmove' ]
    : [ 'mousedown', 'mouseup', 'mousemove' ]
}
