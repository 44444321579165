import Close from './close.svg?react'
import FileCopy from './file-copy.svg?react'
import FileD from './file-d.svg?react'
import Info from './info.svg?react'
import Logo from './logo.svg?react'

export const icons = {
  logo: { Component: Logo },
  close: { Component: Close },
  fileD: { Component: FileD },
  info: { Component: Info },
  fileCopy: { Component: FileCopy },
} as const

export type IconName = keyof typeof icons;
