import { RouteObject } from 'react-router-dom'

import { FriendsPage } from '@/pages/Friends'
import { appRoutes } from '@/shared/config'


export const appRouteConfig: RouteObject[] = [
  {
    path: appRoutes.main,
    element: <FriendsPage />
  },
]
