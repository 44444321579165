import { useIntegration } from '@telegram-apps/react-router-integration'
import { initNavigator } from '@telegram-apps/sdk-react'
import { FC, useMemo } from 'react'
import { Location, Router, useRoutes } from 'react-router-dom'

import { Menu } from '@/widgets/Menu'

import { appRouteConfig } from './config/appRouteConfig'

const AppRoutes: FC<{ location: Location }> = ({ location }) => useRoutes(appRouteConfig, location)

export const AppRouter: FC = () => {
  const navigator = useMemo(() => initNavigator('app-navigation-state'), [])
  const [location, reactNavigator] = useIntegration(navigator)

  return (
    <Router location={location} navigator={reactNavigator}>
      <AppRoutes location={location} />
      <Menu />
    </Router>
  )
}
